import { HttpErrorResponse } from '@angular/common/http';
import { Application, ApplicationStep } from '@mkp/application/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationApiActions = createActionGroup({
  source: 'Application Api',
  events: {
    'Route Application Loaded Success': props<{ application: Application }>(),
    'Route Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Applications Loaded Success': props<{ applications: Application[] }>(),
    'Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Success': props<{ application: Application }>(),
    'Application Refresh Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Not Found': props<{ applicationId: string }>(),
    'More Applications Loaded Success': props<{ applications: Application[] }>(),
    'More Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'One More Application Loaded Success': props<{ applications: Application[] }>(),
    'One More Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Delete Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Application Delete Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Application Delete Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),

    'Application Status Change Success': props<{ application: Application }>(),
    'Application Status Change Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
      statusId: string;
    }>(),
    'Application Status Change Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Applications Presence Confirmed': props<{
      step: ApplicationStep;
      limit: number;
      offset: number;
    }>(),
    'Applications Non Presence Confirmed': emptyProps(),
    'Applications Presence Check Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Status Already Changed': props<{
      applicationId: string;
      statusId: string;
    }>(),
    'New Version Application Loaded Success': props<{
      oldVersionApplication: Application | undefined;
      newVersionApplication: Application;
      statusId: string;
    }>(),
    'New Version Application Loaded Failure': props<{
      errorMessage: HttpErrorResponse;
    }>(),
    'Reload Application For Status Change Failure': props<{
      application: Application;
      statusId: string;
    }>(),
    'Filter Application For Status Change Failure': props<{
      application: Application;
      applicationId: string;
      statusId: string;
    }>(),
  },
});
